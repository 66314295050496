import ReshopperButton from "@components/web/Button";
import { withWebLayout } from "@components/web/Decorators";
import HeaderDefaultWithLogo from "@components/web/HeaderDefaultWithLogo";
import { useLocalization } from "@hooks/localization";
import { useQueryParameter } from "@hooks/navigation";
import { NoSsr } from "@material-ui/core";
import styles from "./update-available.module.scss";

export default withWebLayout(() => {
    const [platform] = useQueryParameter("platform");
    const t = useLocalization();

    return <div className={styles.root}>
        <NoSsr>
            <HeaderDefaultWithLogo />
            <div className={styles.headerText}>{t("Update_Available_Header")}</div>
            <p className={styles.text}>
                {t("Update_Available_Subheader")} <br /><br />
                {t("Update_Available_Text")}
            </p>

            {platform === "ios" &&
                <ReshopperButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (platform === "ios")
                            window.location.href = 'https://itunes.apple.com/app/reshopper/id551998942';
                    }}>
                    {t("Update_Available_ButtonText")}
                </ReshopperButton>
            }

            {platform === "android" &&
                <p className={styles.text}>{t("Update_Available_AndroidText")}</p>
            }

        </NoSsr>
    </div>;
});
